<template>
  <div class="title-item-container" :style="{'font-size': fontSize + 'px'}">
    <div class="title">{{ title }}</div>
    <div class="value"><count-to :start-val="0" :decimals="2" :end-val="value" /></div>
    <div class="ratio">环比：{{ ratio }}%</div>
  </div>
</template>

<script>
import { numFormat } from '@/utils/dealNumber'
import CountTo from 'vue-count-to'
import autoFontSize from '@/mixins/autoFontSize'
export default {
  components: { CountTo },
  mixins: [autoFontSize],
  props: {
    title: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: 0
    },
    ratio: {
      type: [String, Number],
      default: 0
    }
  },
  methods: {
    numFormat
  }
}
</script>

<style lang="less" scoped>
.title-item-container {
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .title {
    font-size: 1.14em;
    height: 1.78em;
    line-height: 1.42em;
    color: #414141;
  }
  .value {
    font-size: 1.28em;
    line-height: 1.78em;
    font-weight: 700;
  }
  .ratio {
    font-size: 1em;
    line-height: 1.42em;
    color: #414141;
  }
}
</style>
